import { NotificationService } from '@careacademy/ca-design-system'
import { format } from 'date-fns'
import Vue from 'vue'
import type { Route } from 'vue-router'

import type { BulkAction } from '@/javascript/components/bulk_actions_dialog'
import type {
	Agency,
	AgencyBackOffice,
	AgencyInformation,
	CurrentUser,
	EmittedEvent,
	Language,
} from '@/javascript/interfaces'
import type { CAMember } from '@/javascript/interfaces'
import { standardDateFormat } from '@/javascript/lib/validate_date/process-date-string'
import AdministratorStore from '@/javascript/vuejs/stores/AdministratorStore'
import CurriculumsStore from '@/javascript/vuejs/stores/CurriculumsStore'
import FranchisorStore from '@/javascript/vuejs/stores/FranchisorStore'
import GroupsStore from '@/javascript/vuejs/stores/GroupsStore'
import MemberTrainingStore from '@/javascript/vuejs/stores/MemberTrainingStore'
import TeamStore from '@/javascript/vuejs/stores/TeamStore'

import type { MemberAction } from '../services/administratorDashboard/interfaces'
import type { Member } from '../services/members/interfaces'

export const state = Vue.observable({
	agency: {} as Agency,
	agencyBackOffice: {
		backOfficeIntegrationEnabled: false,
		backOfficeIntegrationProviderName: '',
		marketingProductName: '',
	} as AgencyBackOffice,
	agencyCreatedAt: '',
	agencyId: null as number | null,
	agencyInfo: {} as AgencyInformation,
	agencyMembers: [] as Member[],
	agencyName: '',
	bulkAction: {
		action: '' as MemberAction,
		actionDescriptionText: '',
		actionText: '',
		additionalMembersText: '',
		bodyText: '',
		memberIds: [] as number[],
		membersText: '',
	} as BulkAction,
	checkedOnAnotherPage: false,
	currentDate: '',
	currentDateString: '',
	currentPage: 1,
	currentUrl: null as string | null,
	currentUser: null as CurrentUser | null,
	currentView: '',
	displayText: {
		agencies: 'Agencies',
		agency: 'agency',
		dashboard: 'dashboard',
		member: 'caregiver',
	},
	eligibleAgenciesUrl: '/api/agencies/eligible_agencies',
	emittedEvent: '' as EmittedEvent,
	franchisorSSOPath: null as string | null,
	includeGroupMembersLink: false,
	isAdvanceReporting: false,
	isBillingAdmin: false,
	isInTeamFlow: false,
	isMemberOnly: false,
	isMobileView: false,
	isTeamFlowEnabled: false,
	page: '',
	previousRoute: null as Route | null,
	previousRouteUrl: null as string | null,
	routeError: '',
	selectAllChecked: false,
	selectAllCheckedOnPage: {} as { [page: number]: boolean },
	selectedCATableMembers: [] as CAMember[],
	selectedCheckboxNotOnPage: false,
	selectedLanguage: '' as Language,
	showAddAllToOrganizations: false,
	showPopover: {
		id: null as number | null,
		name: '' as PopoverOptions,
	},
	showTrainingDashboard: true,
	someSelectAllCheckboxIsTrue: false,
	teamMemberText: 'caregiver',
	twilioSuccessCode: '',
	twilioUserErrorMessages: {} as { [key: string]: string },
	useDesignSystem: false,
	userId: '',
})

const store: any = {
	get agency(): Agency {
		return state.agency
	},
	set agency(value) {
		state.agency = value
	},

	get agencyBackOffice(): AgencyBackOffice {
		return state.agencyBackOffice
	},
	set agencyBackOffice(value) {
		state.agencyBackOffice = value
	},

	get agencyCreatedAt(): string {
		return state.agencyCreatedAt
	},
	set agencyCreatedAt(value) {
		state.agencyCreatedAt = value
	},

	get agencyId(): number | null {
		return state.agencyId
	},
	set agencyId(value) {
		state.agencyId = value
	},

	get agencyInfo(): AgencyInformation {
		return state.agencyInfo
	},
	set agencyInfo(value) {
		state.agencyInfo = value
	},

	get agencyMembers(): Member[] {
		return state.agencyMembers
	},
	set agencyMembers(value) {
		state.agencyMembers = value
	},

	get agencyName(): string {
		return state.agencyName
	},
	set agencyName(value) {
		state.agencyName = value
	},

	get bulkAction(): BulkAction {
		return state.bulkAction
	},
	set bulkAction(value) {
		state.bulkAction = value
	},

	get checkedOnAnotherPage(): boolean {
		return state.someSelectAllCheckboxIsTrue && state.selectedCheckboxNotOnPage
	},

	get currentDate(): string {
		return format(new Date(), 'EEE, dd MMMM yyyy hh:mm:ss xxxx')
	},

	get currentDateString(): string {
		return standardDateFormat(new Date())
	},

	get currentPage(): number {
		return state.currentPage
	},
	set currentPage(value) {
		state.currentPage = value
	},

	get currentUser(): CurrentUser | null {
		return state.currentUser
	},
	set currentUser(value) {
		state.currentUser = value
	},

	get currentUrl(): string | null {
		return state.currentUrl
	},
	set currentUrl(value) {
		state.currentUrl = value
	},

	get currentView(): string {
		return state.currentView
	},
	set currentView(value) {
		state.currentView = value
	},

	get displayText(): { [key: string]: string } {
		switch (true) {
			case store.isTeamFlowEnabled:
			case FranchisorStore.isTeamFlowEnabled:
				return {
					agencies: 'Organizations',
					agency: 'organization',
					dashboard: 'insights',
					member: FranchisorStore.isTeamFlowEnabled && !state.agencyId ? 'member' : 'team member',
				}
			default:
				return {
					agencies: 'Agencies',
					agency: 'agency',
					dashboard: 'dashboard',
					member: 'caregiver',
				}
		}
	},

	get eligibleAgenciesUrl(): string {
		return state.eligibleAgenciesUrl
	},

	get emittedEvent(): EmittedEvent {
		return state.emittedEvent
	},
	set emittedEvent(value) {
		state.emittedEvent = ''
		state.emittedEvent = value
	},

	get franchisorSSOPath(): string | null {
		return state.franchisorSSOPath
	},
	set franchisorSSOPath(value) {
		state.franchisorSSOPath = value
	},

	get includeGroupMembersLink(): boolean {
		return state.includeGroupMembersLink
	},
	set includeGroupMembersLink(value) {
		state.includeGroupMembersLink = value
	},

	get isAdvanceReporting(): boolean {
		return state.isAdvanceReporting
	},
	set isAdvanceReporting(value) {
		state.isAdvanceReporting = value
	},

	get isBillingAdmin(): boolean | undefined {
		return state.currentUser?.isSuper ||
			state.currentUser?.isFranchisorManager ||
			state.currentUser?.isAgencyBillingManager
	},

	get isInTeamFlow(): boolean {
		return state.currentView === 'team'
	},

	get isMemberOnly(): boolean | undefined {
		return !state.currentUser?.isSuper &&
			!state.currentUser?.isAgencyBillingManager &&
			!state.currentUser?.isAgencyManager &&
			!state.currentUser?.isFranchisorManager &&
			!state.currentUser?.isMoreThanCaregiver &&
			state.currentUser?.isCaregiver
	},

	get isMobileView(): boolean {
		const element = document.querySelector('body')

		return element ? element.classList.contains('mobile-view') : false
	},

	get isTeamFlowEnabled(): boolean {
		return state.agency?.enabled_team_flow
	},

	get page(): string {
		return state.page
	},
	set page(value) {
		state.page = value
	},

	get previousRouteUrl(): string | null {
		return state.previousRouteUrl
	},
	set previousRouteUrl(value) {
		state.previousRouteUrl = value
	},

	get previousRoute(): Route | null {
		return state.previousRoute
	},
	set previousRoute(value) {
		state.previousRoute = value
	},

	get routeError(): string {
		return state.routeError
	},
	set routeError(value) {
		state.routeError = value
	},

	get selectAllChecked(): boolean {
		return state.selectAllChecked
	},
	set selectAllChecked(value) {
		state.selectAllChecked = value
	},

	get selectAllCheckedOnPage(): { [page: number]: boolean } {
		return state.selectAllCheckedOnPage
	},
	set selectAllCheckedOnPage(value) {
		state.selectAllCheckedOnPage = value
	},

	get selectedCATableMembers(): CAMember[] {
		return state.selectedCATableMembers
	},
	set selectedCATableMembers(value) {
		state.selectedCATableMembers = value
	},

	get selectedCheckboxNotOnPage(): boolean {
		return !state.selectAllCheckedOnPage[TeamStore.pagination.currentPage]
	},

	get selectedLanguage(): Language {
		return state.selectedLanguage
	},
	set selectedLanguage(value) {
		state.selectedLanguage = value
	},

	get showAddAllToOrganizations(): boolean {
		if (!state.currentUser) {
			return false
		}

		return (state.currentUser.isFranchisorManager || state.currentUser.isSuper) && !!state.currentUser.franchisor
	},

	get someSelectAllCheckboxIsTrue(): boolean {
		return Object.values(state.selectAllCheckedOnPage).some(value => value === true)
	},

	get showFranchise(): boolean {
		if (!state.currentUser) {
			return false
		}

		return state.currentUser.isFranchisorManager || state.currentUser.isSuper
	},

	get showPopover(): { id: number | null, name: PopoverOptions } {
		return state.showPopover
	},
	set showPopover(value) {
		state.showPopover = value
	},

	get showTrainingDashboard(): boolean {
		return state.showTrainingDashboard
	},
	set showTrainingDashboard(value) {
		state.showTrainingDashboard = value
	},

	get teamMemberText(): string {
		return store.isTeamFlowEnabled ? 'team member' : 'caregiver'
	},

	get twilioSuccessCode(): string {
		return state.twilioSuccessCode
	},
	set twilioSuccessCode(value) {
		state.twilioSuccessCode = value
	},

	get twilioUserErrorMessages(): { [key: string]: string } {
		return state.twilioUserErrorMessages
	},
	set twilioUserErrorMessages(value) {
		state.twilioUserErrorMessages = value
	},

	get useDesignSystem(): boolean {
		return this.isTeamFlowEnabled ?? this.isInTeamFlow
	},

	get userId(): string {
		return state.userId
	},
	set userId(value) {
		state.userId = value
	},

	// Actions
	clearSelectedValues(): void {
		state.selectedCATableMembers = []
		state.selectAllCheckedOnPage = {}
		state.selectAllChecked = false
	},

	closeModal(): void {
		state.emittedEvent = ''
		state.selectedCATableMembers = []

		AdministratorStore.selectedMembers = []
		MemberTrainingStore.resetData()
		CurriculumsStore.resetData()
		GroupsStore.action = ''
		GroupsStore.selectedMemberId = undefined
	},

	closePopover(): void {
		state.showPopover = { id: null, name: '' }
	},

	countDownTimer({
		newMessage,
		response,
	}: {
		newMessage?: string,
		response?: any
	}): void {
		let countdown = 4
		const interval = setInterval(() => {
			countdown--
			if (newMessage) { this.onHandleSuccessMessages({ message: `${newMessage} Refreshing page in ${countdown} seconds.` }) }

			if (response) {
				this.onHandleSuccessMessages({ response: `${response.data?.success_messages} Refreshing page in ${countdown} seconds.` })
			}

			if (countdown === 0) {
				clearInterval(interval)
				window.location.reload()
			}
		}, 1000)
	},

	formatSeatsUsed(seatUsed = 0): number {
		return Math.trunc(seatUsed) ?? 0
	},

	getAPIErrorMessage(error: any, defaultErrorMessage = 'An error occurred, please try again'): string[] | string {
		return error.response?.data?.message ||
			error.message ||
			error.responseJSON?.message ||
			defaultErrorMessage
	},

	getAPISuccessMessage(response: any, defaultSuccessMessage = 'Successfully completed'): string[] | string {
		return response.data?.success ||
			response.responseText ||
			response.data?.message ||
			response.message ||
			response.data?.success_messages ||
			response.success ||
			response.message ||
			response.responseJSON?.message ||
			response.data ||
			response ||
			defaultSuccessMessage
	},

	getTwilioUserErrorMessage(errorCode: string): string {
		return state.twilioUserErrorMessages[errorCode]
	},

	hasTwilioUserError(errorCode: string): boolean {
		return state.twilioUserErrorMessages.hasOwnProperty(errorCode)
	},

	identifyPage(url: string) {
		const teamPagePattern = /^\/agencies\/\d+\/team\/?$/

		const groupMembersPagePattern = /^\/agencies\/\d+\/team\/\d+\/members\/?(\d+\/?)?$/

		if (teamPagePattern.test(url)) {
			return 'teamPage'
		} else if (groupMembersPagePattern.test(url)) {
			return 'groupMembersPage'
		} else {
			return 'unknownPage'
		}
	},

	notify({
		duration,
		message,
		type,
	}: {
		duration?: number,
		message: string | null,
		type: 'error' | 'success',
		useDesignSystem?: boolean,
	}): void {
		if (!message) {
			return
		}

		if (type === 'error') {
			NotificationService.methods.error(message, duration)
		} else {
			NotificationService.methods.success(message, duration)
		}
	},

	onHandleErrorMessages({
		duration,
		error,
	}: {
		duration?: number,
		error: any,
		useDesignSystem: boolean
	}): void {
		if (error.name === 'SecurityError') {
			this.notify({
				duration,
				message: error.message ?? error,
				type: 'error',
			})

			return
		}

		const errorMessages = error.data?.error ??
			error.response?.data?.error ??
			error.response?.data?.messages ??
			error.response?.data?.message ??
			error.response?.error ??
			error.response?.errors ??
			error.data?.error_messages ??
			error.responseJSON?.message ??
			error.message ??
			error.json ??
			error ??
			'An error occurred. Please try again.'

		const message = typeof error === 'string' ? error : errorMessages

		console.error(message)

		if (typeof message === 'string') {
			this.notify({
				message: message as 'error',
				type: 'error',
			})
		} else {
			for (const element in message) {
				for (const issue of message[element]) {
					console.error(issue)
					this.notify({
						message: issue as 'error',
						type: 'error',
					})
				}
			}
		}
	},

	onHandleSuccessMessages({
		duration,
		message,
		response,
	}: {
		duration?: number,
		message?: string,
		response?: any,
		useDesignSystem?: boolean
	}): void {
		if (message) {
			this.notify({
				duration,
				message,
				type: 'success',
			})
		} else if (response) {
			const successes = response.data?.success ??
				response.responseText ??
				response.data?.message ??
				response.message ??
				response.data?.success_messages ??
				response.success ??
				response.message ??
				response.responseJSON?.message ??
				response.data ??
				response ??
				'Successfully completed!'

			if (typeof successes === 'string') {
				this.notify({
					message: successes,
					type: 'success',
				})
			} else if (Array.isArray(successes)) {
				successes.forEach((message: string) => this.notify({
					message,
					type: 'success',
				}))
			}
		}
	},

	onHandleTextMessageResponseError({ response }: { response?: any }): void {
		const errors = response?.data?.error_messages ?? response?.data?.error ?? response.error

		if (typeof errors === 'string') {
			this.notify({
				message: errors,
				type: 'error',
			})
		} else {
			!!errors && errors.map((message: 'error') => this.notify({
				message,
				type: 'error',
			}))
		}
	},

	openSendBulkMessage(): void {
		state.emittedEvent = 'send-bulk-message'
	},

	openSendGroupMessage(): void {
		state.emittedEvent = 'send-group-message'
	},

	openSendMemberMessage(): void {
		AdministratorStore.selectedMembers = []
		state.emittedEvent = 'send-member-message'
	},

	resetCAModal(): void {
		state.emittedEvent = ''
	},

	trimGroupMembersLink(route: string): string {
		const indexOfSearchString = route.indexOf('members')

		if (indexOfSearchString !== -1) {
			return route.substring(0, indexOfSearchString + 'members'.length)
		}

		// If the search string is not found, return the original input string
		return route
	},
}

export default store

export type PopoverOptions
	= ''
	| 'Due date'
	| 'Edit name'
